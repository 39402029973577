<template>
    <Toast />
    <Toast position="center" style="min-width:15vw" group="dialogtoast">
        <template #message="slotProps">
            <div class="flex flex-column">
                <div class="text-center">
                    <i class="pi pi-exclamation-triangle" style="font-size: 3rem"></i>
                    <h5>{{slotProps.message.detail}}</h5>
                </div>
                <div class="grid p-fluid">
                    <div class="col-12">
                        <Button class="p-button-success" label="确定" @click="dialogmsgClose" />
                    </div>
                </div>
            </div>
        </template>
    </Toast>
    <ConfirmDialog></ConfirmDialog>
    <div class="layout-sidebar">
        <div class="layout-menu-container">
            <div>
                <Menubar :model="items">
                </Menubar>
            </div>
            <Tree :value="nodes" @nodeSelect="onNodeSelect" v-model:expandedKeys="expandedKey"
                v-model:selectionKeys="selectedKey" selectionMode="single">
            </Tree>
        </div>
    </div>
    <div class="layout-main-container">
        <div class="layout-main">
            <div class="card">
                <h5>{{listTitle}}</h5>
                <DataTable :value="outstocks" v-model:selection="selectedRow" :metaKeySelection="false"
                    v-model:filters="filters1" filterDisplay="menu" dataKey="id"
                    :globalFilterFields="['orderid','name']" scrollable :tableStyle="styleHeight"
                    :scrollHeight="sHeight" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
                    :rows="pagesize2" :virtualScrollerOptions="vsOptionFun()" :rowsPerPageOptions="[50, 100, 200, 500]"
                    :totalRecords="allRecords" :first="first"
                    paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
                    currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3"
                    @page="listPage($event)" @sort="onSort($event)">
                    <template #header>
                        <div class="flex justify-content-between flex-column sm:flex-row">
                            <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                                @click="clearFilter1()" />
                            <span class="p-input-icon-left mb-2" style="width:30%">
                                <i class="pi pi-search" />
                                <InputText v-model="filters1['global'].value" placeholder="输入订单号或客户名称或产品名称模糊查询"
                                    style="width:100%" @keyup.enter="customFilter();" />
                            </span>
                        </div>
                    </template>
                    <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;"
                        headerStyle="width:3em;flex:0;"></Column>
                    <Column v-for="col of listcolumns" :field="col.field" :bodyStyle="col.bodyStyle"
                        :headerStyle="col.headerStyle" :showFilterOperator="col.showFilterOperator" :header="col.header"
                        :key="col.field" :filterMatchModeOptions="col.filterMatchModeOptions" :sortable="col.sortable"
                        :frozen="col.frozen">
                        <template v-if="col.islink || col.oneline" #body="slotProps">
                            <Button v-if="col.islink" :label="slotProps.data.orderid"
                                @click="openOutStock(slotProps.data)" class="p-button-link oneline" />
                            <label v-if="col.oneline" class="oneline"
                                v-tooltip.top="{ value: slotProps.data[col.field], disabled: false }">{{slotProps.data[col.field]}}</label>
                        </template>
                        <template #filter="{filterModel}">
                            <InputText type="text" v-model="filterModel.value" class="p-column-filter"
                                placeholder="输入筛选" />
                        </template>
                        <template #filterclear="{filterCallback}">
                            <Button type="button" label="清除" @click="filterCallback()"
                                class="p-button-secondary"></Button>
                        </template>
                        <template #filterapply="{filterCallback}">
                            <Button type="button" label="查询" @click="filterCallback();customFilter();"
                                class="p-button-success"></Button>
                        </template>
                        <template #filterfooter="{filterCallback}">
                            <Button type="button" label="保存查询方案" @click="filterCallback();saveCustomFilter();"
                                class="p-button-outlined p-button-success"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
        <AppFooter />
    </div>
    <Dialog :header="recordsubject" id="mainDialog" class="p-dialog-maximized" @maximize="selfmaximize"
        @unmaximize="selfmaximize" @hide="dialogClose" v-model:visible="displayMaximizable" :style="{width: '70vw'}"
        :maximizable="true" :modal="true">
        <p class="p-m-0">
            <NewUniversalPage ref="universalpage" :closeButLoadingMethod="closeButLoading" :currentRow="currentRow"
                :freshListMethod="freshList" :pageJsonConfig="pageJsonConfig" :closeParentMehod="closeMaximizable"
                :importAddColsMethod="importAddCols" :isReadOnly="readOnly" />
        </p>
        <template #footer>
            <div class="flex p-3 card">
                <Button label="关闭" icon="pi pi-power-off" class="ml-auto" @click="closeMaximizable" />
                <Button v-if="!readOnly" label="保存" icon="pi pi-check" @click="saveData" :loading="butLoading"
                    autofocus />
            </div>
        </template>
    </Dialog>
    <Dialog header="保存查询方案" v-model:visible="displayQueryPlan" :style="{width: '25vw'}" :modal="true">
        <div class="card">
            <div class="grid p-fluid mt-3">
                <div class="field col-12 md:col-12">
                    <span class="p-float-label">
                        <InputText type="text" id="queryPlanName" v-model="queryPlanObj.name" />
                        <label for="queryPlanName">查询方案名称</label>
                    </span>
                </div>
            </div>
        </div>
        <template #footer>
            <Button label="取消" icon="pi pi-power-off" :loading="butLoading" @click="closeQueryPlan"
                class="p-button-text" />
            <Button label="保存" icon="pi pi-check" :loading="butLoading" @click="saveQueryPlan" autofocus />
        </template>
    </Dialog>
    <Dialog header="出库" v-model:visible="outstockShow" :style="{width: '70vw'}" :modal="true">
        <DataTable v-if="!startoutstock" :value="orders" v-model:selection="selectedRow2" :metaKeySelection="false"
            v-model:filters="filters2" filterDisplay="menu" dataKey="id" :globalFilterFields="['orderid','name']"
            scrollable scrollHeight="500px" responsiveLayout="scroll" :loading="loading" :paginator="true" :lazy="true"
            :rows="100" :virtualScrollerOptions="{ itemSize: 40 }" :rowsPerPageOptions="[50, 100, 200, 5000]"
            :totalRecords="allRecords2" :first="first2"
            paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
            currentPageReportTemplate="共{totalRecords}记录 当前页{first} 到 {last}" class="p-mt-3" @page="listPage2($event)">
            <template #header>
                <div class="flex justify-content-between flex-column sm:flex-row">
                    <Button type="button" icon="pi pi-filter-slash" label="Clear" class="p-button-outlined mb-2"
                        @click="clearFilter2()" />
                    <span class="p-input-icon-left mb-2">
                        <i class="pi pi-search" />
                        <InputText v-model="filters2['global'].value" placeholder="输入关键字查询" style="width: 100%" />
                    </span>
                </div>
            </template>
            <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
            </Column>
            <Column field="orderid" bodyStyle="min-width:150px;left:0px" headerStyle="min-width:150px;left:0px"
                :showFilterOperator="false" header="OrderId" frozen :filterMatchModeOptions="matchModes"
                :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="outtime" bodyStyle="min-width:120px;left:0px" headerStyle="min-width:120px;left:0px"
                :showFilterOperator="false" header="出库时间" frozen :filterMatchModeOptions="matchModes" :sortable="false">
                <template #body="slotProps">
                    <Calendar :showTime="false" v-model="slotProps.data['outtime']" hourFormat="24"
                        dateFormat="yy-mm-dd" style="width:110px" />
                </template>
            </Column>
            <Column field="name" style="min-width:130px" :showFilterOperator="false" header="Name"
                :filterMatchModeOptions="matchModes" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="buyercode" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:150px" header="BuyerCode" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="mpn" :showFilterOperator="false" :filterMatchModeOptions="matchModes" style="min-width:150px"
                header="MPN" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="itemcode" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:130px" header="ItemCode" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="productname" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:180px" header="ProductName" :sortable="true">
                <template #body="slotProps">
                    <label class="oneline"
                        v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="salesdate" :showFilterOperator="false" style="min-width:150px" header="SalesDate"
                :sortable="true" :filterMatchModeOptions="timeMatchModes">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="salesquantity" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                style="min-width:150px" header="SalesQuantity" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="marginreturnnum" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                style="min-width:150px" header="圈货退货数量" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="unitprice" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                style="min-width:150px" header="UnitPrice" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="total" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                style="min-width:130px" header="Total" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="finaltotal" :showFilterOperator="false" :filterMatchModeOptions="numMatchModes"
                style="min-width:130px" header="FinalTotal" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="ordertype" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:180px" header="OrderType" :sortable="true">
                <template #body="slotProps">
                    <label class="oneline"
                        v-tooltip.top="{ value: slotProps.data.ordertype, disabled: false }">{{slotProps.data.ordertype}}</label>
                </template>
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="isreturnname" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:120px" header="IsReturn" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="rmaid" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:130px" header="RMAID" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
            <Column field="operator" :showFilterOperator="false" :filterMatchModeOptions="matchModes"
                style="min-width:120px" header="操作员" :sortable="true">
                <template #filter="{filterModel}">
                    <InputText type="text" v-model="filterModel.value" class="p-column-filter" placeholder="输入筛选" />
                </template>
                <template #filterclear="{filterCallback}">
                    <Button type="button" label="清除" @click="filterCallback()" class="p-button-secondary"></Button>
                </template>
                <template #filterapply="{filterCallback}">
                    <Button type="button" label="查询" @click="filterCallback();customFilter2();"
                        class="p-button-success"></Button>
                </template>
            </Column>
        </DataTable>
        <div v-if="startoutstock" class="grid p-fluid mt-3">
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
        </div>
        <template #footer>
            <Button v-if="startoutstock" label="关闭" icon="pi pi-power-off" @click="closeSaveOutStock" />
            <Button v-if="!startoutstock" label="生成出库单" icon="pi pi-check" :loading="butLoading" @click="saveOutStock"
                autofocus />
        </template>
    </Dialog>
    <Dialog header="调货出库" v-model:visible="transferoutstockShow" :style="{width: '70vw'}" :modal="true">
        <DataTable v-if="!startoutstock" :value="transferorders" dataKey="id" scrollable scrollHeight="250px"
            responsiveLayout="scroll" :loading="loading" class="p-mt-3">
            <template #header>
                <div class="flex justify-content-center align-items-center">
                    <h6 class="m-0">待调货订单</h6>
                </div>
            </template>
            <Column field="orderid" style="min-width:150px" header="OrderId" frozen>
            </Column>
            <Column field="name" style="min-width:130px" header="Name">
            </Column>
            <Column field="buyercode" style="min-width:150px" header="BuyerCode">
            </Column>
            <Column field="mpn" style="min-width:150px" header="MPN">
            </Column>
            <Column field="itemcode" style="min-width:130px" header="ItemCode">
            </Column>
            <Column field="productname" style="min-width:180px" header="ProductName">
                <template #body="slotProps">
                    <label class="oneline"
                        v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                </template>
            </Column>
            <Column field="salesdate" style="min-width:150px" header="SalesDate">
            </Column>
            <Column field="salesquantity" style="min-width:150px" header="需调货数">
            </Column>
            <Column field="unitprice" style="min-width:150px" header="UnitPrice">
            </Column>
            <Column field="ordertype" style="min-width:180px" header="OrderType">
                <template #body="slotProps">
                    <label class="oneline"
                        v-tooltip.top="{ value: slotProps.data.ordertype, disabled: false }">{{slotProps.data.ordertype}}</label>
                </template>
            </Column>
            <Column field="transferorder" style="min-width:120px" header="调货订单">
            </Column>
            <Column style="min-width:130px" header="操作">
                <template #body="slotProps">
                    <Button label="调货" @click="opentransferOutStock(slotProps.data)" class="p-button-link" />
                </template>
            </Column>
        </DataTable>
        <div v-if="startoutstock" class="grid p-fluid mt-3">
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <ProgressBar :value="importProgressValue" />
            </div>
            <div v-if="startoutstock" class="field col-12 md:col-12">
                <label>{{importProgress}}</label>
            </div>
        </div>
        <template #footer>
            <Button v-if="startoutstock" label="关闭" icon="pi pi-power-off" @click="closetransferSaveOutStock" />
            <Button v-if="!startoutstock" label="生成出库单" icon="pi pi-check" :loading="butLoading"
                @click="savetransferOutStock" autofocus />
        </template>
    </Dialog>
    <Dialog header="选择调货订单" v-model:visible="cantransferordersShow" :style="{width: '70vw'}" :modal="true">
        <DataTable :value="cantransferorders" dataKey="id" v-model:selection="selectedRow3" v-model:filters="filters3"
            filterDisplay="menu" :globalFilterFields="['orderid','name','buyercode']" scrollable scrollHeight="250px"
            responsiveLayout="scroll" :loading="loading" class="p-mt-3">
            <template #header>
                <div class="flex justify-content-center align-items-center">
                    <h6 class="m-0">可调货订单</h6>
                </div>
                <span class="p-input-icon-left mb-2">
                    <i class="pi pi-search" />
                    <InputText v-model="filters3['global'].value" placeholder="输入关键字查询" style="width: 100%" />
                </span>
            </template>
            <Column selectionMode="multiple" frozen bodyStyle="width:3em;flex:0;" headerStyle="width:3em;flex:0;">
            </Column>
            <Column field="orderid" bodyStyle="min-width:150px;left:0px" headerStyle="min-width:150px;left:0px"
                header="OrderId" frozen>
            </Column>
            <Column field="name" style="min-width:130px" header="Name">
            </Column>
            <Column field="buyercode" style="min-width:150px" header="BuyerCode">
            </Column>
            <Column field="mpn" style="min-width:150px" header="MPN">
            </Column>
            <Column field="itemcode" style="min-width:130px" header="ItemCode">
            </Column>
            <Column field="productname" style="min-width:180px" header="ProductName">
                <template #body="slotProps">
                    <label class="oneline"
                        v-tooltip.top="{ value: slotProps.data.productname, disabled: false }">{{slotProps.data.productname}}</label>
                </template>
            </Column>
            <Column field="salesdate" style="min-width:150px" header="SalesDate">
            </Column>
            <Column field="salesquantity" style="min-width:150px" header="SalesQuantity">
            </Column>
            <Column field="unitprice" style="min-width:150px" header="UnitPrice">
            </Column>
            <Column field="ordertype" style="min-width:180px" header="OrderType">
                <template #body="slotProps">
                    <label class="oneline"
                        v-tooltip.top="{ value: slotProps.data.ordertype, disabled: false }">{{slotProps.data.ordertype}}</label>
                </template>
            </Column>
            <Column field="transferquantity" style="min-width:130px" header="调货数量">
                <template #body="slotProps">
                    <InputText type="text" style="width:110px" v-model="slotProps.data['transferquantity']" />
                </template>
            </Column>
        </DataTable>
        <template #footer>
            <Button label="关闭" icon="pi pi-power-off" @click="closeCantransferOrders" />
            <Button label="添加" icon="pi pi-check" :loading="butLoading" @click="addtransferOutStock" autofocus />
        </template>
    </Dialog>
</template>
<style type="text/css">
    .oneline {
        width: 160px;
        float: left;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
</style>
<script>
    import {
        ref,
    } from 'vue';
    import {
        FilterMatchMode,
        FilterOperator
    } from 'primevue/api';
    import {
        useConfirm
    } from "primevue/useconfirm";
    import ConfigService from '../../../service/ConfigService';
    import CommonJs from '../../../js/Common.js';
    import MessageTip from '../../../components/Message';
    import AppFooter from '../../../AppFooter.vue';
    import NewUniversalPage from '../../../components/NewUniversalPage.vue';
    import requests from '../../../service/newaxios';
    import * as Base64 from 'js-base64';
    // import outstockPageJson from "@/data/outstockConfig.json";
    export default {
        setup() {
            const confirm = useConfirm();
            const currentMenuId = '722';
            const allRecords = -1;
            const allRecords2 = ref(0);
            const listcolumns = ref();
            const usermoduleauth = [];
            return {
                confirm,
                currentMenuId,
                allRecords,
                allRecords2,
                listcolumns,
                usermoduleauth
            };
        },
        data() {
            return {
                matchModes: [{
                        label: '以字符开始',
                        value: FilterMatchMode.STARTS_WITH
                    },
                    {
                        label: '以字符结束',
                        value: FilterMatchMode.ENDS_WITH
                    },
                    {
                        label: '包含字符',
                        value: FilterMatchMode.CONTAINS
                    },
                ],
                timeMatchModes: [{
                        label: '小于',
                        value: FilterMatchMode.DATE_BEFORE
                    },
                    {
                        label: '大于',
                        value: FilterMatchMode.DATE_AFTER
                    },
                ],
                numMatchModes: [{
                        label: '大于等于',
                        value: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                    },
                    {
                        label: '小于等于',
                        value: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                    },
                ],
                pageJsonConfig: {},
                orders: [],
                transferorders: [],
                cantransferorders: [],
                allwaitoutorders: [],
                importProgress: '',
                importProgressValue: 0,
                butLoading: false,
                recordsubject: '',
                readOnly: true,
                displayMaximizable: false,
                displayQueryPlan: false,
                displayPosition: false,
                outstockShow: false,
                cantransferordersShow: false,
                transferoutstockShow: false,
                startoutstock: false,
                position: 'bottomleft',
                filters1: {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    }
                },
                filters2: null,
                filters3: null,
                currentRow: {
                    bindpage: 'outstock',
                    id: '-1',
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: ''
                },
                currenttransferrow: null,
                selectedRow: ref(),
                selectedRow2: ref(),
                selectedRow3: ref(),
                sHeight: '600px',
                styleHeight: '',
                minHeight: 'min-height:600px',
                first: 0,
                first2: 0,
                listTitle: '出库列表',
                selectedKey: {
                    722: true
                },
                expandedKey: null,
                outstocks: [],
                pagesize: 50,
                pagesize2: 50,
                loading: true,
                nodes: [],
                items: [{
                    label: '出库操作',
                    icon: 'pi pi-fw pi-users',
                    items: [{
                            label: '出库',
                            icon: 'pi pi-fw pi-user-plus',
                            command: () => {
                                this.createOutStock();
                            }
                        },
                        {
                            separator: true
                        },
                        {
                            label: '删除',
                            icon: 'pi pi-fw pi-trash',
                            command: () => {
                                this.delOutStock();
                            }
                        }
                    ]
                }],
                queryPlanObj: {
                    name: '',
                    id: '-1',
                    parentid: this.currentMenuId,
                    queryconfig: '',
                },
            }
        },
        configService: null,
        created() {
            this.pagesize2 = CommonJs.GetDefaultPageSize(this);
            this.configService = new ConfigService();
            MessageTip.initMsg();
            this.init();
        },
        methods: {
            init() {
                this.configService.getoutstock(sessionStorage.getItem("appkey")).then(data => {
                    console.log(data);
                    this.listcolumns = data.list;
                    this.pageJsonConfig = data.page;
                    this.initFilters1();
                    this.initFilters2();
                    this.initFilters3();
                    this.loadMenuTree();
                    this.importProgress = '';
                    this.orders = [];
                    this.transferorders = [];
                    this.cantransferorders = [];
                    this.allwaitoutorders = [];
                    this.startoutstock = false;
                    this.currenttransferrow = null;
                    this.importProgressValue = 0;
                    this.sHeight = (window.innerHeight - 280) + "px";
                    this.styleHeight = 'height:' + (window.innerHeight - 300) + 'px;';
                    this.minHeight = "height:" + (window.innerHeight - 132) + "px";
                    this.loadUserAuth();
                });
            },
            vsOptionFun() {
                return CommonJs.vsOptionFun(this.pagesize2, this.allRecords);
            },
            initFilters1() {
                CommonJs.initFilters(this.filters1, this.listcolumns, this.setinitFilters);
            },
            setinitFilters(v_filters1) {
                this.filters1 = v_filters1;
            },
            clearFilter1() {
                this.initFilters1();
                this.loadData(1);
            },
            initFilters2() {
                this.filters2 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'orderid': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'name': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'buyercode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'mpn': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'itemcode': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'productname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'salesdate': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.DATE_BEFORE
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.DATE_AFTER
                        }]
                    },
                    'salesquantity': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'unitprice': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'total': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'finaltotal': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.LESS_THAN_OR_EQUAL_TO
                        }, {
                            value: null,
                            matchMode: FilterMatchMode.GREATER_THAN_OR_EQUAL_TO
                        }]
                    },
                    'ordertype': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'isreturnname': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'rmaid': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                    'operator': {
                        operator: FilterOperator.AND,
                        constraints: [{
                            value: null,
                            matchMode: FilterMatchMode.CONTAINS
                        }]
                    },
                }
            },
            clearFilter2() {
                this.initFilters2();
            },
            initFilters3() {
                this.filters3 = {
                    'global': {
                        value: null,
                        matchMode: FilterMatchMode.CONTAINS
                    },
                }
            },
            setlistTitleFun(val) {
                this.listTitle = val;
            },
            loadMenuTree() {
                CommonJs.loadMenuTree('721', this.currentMenuId, this.$route, this.loadData, this.setlistTitleFun, this
                    .setloadMenuTree);
            },
            loadUserAuth() {
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'parentid',
                        value: '721',
                        operation: '='
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{usermoduleauthlist(where:" + JSON.stringify(listwhere) +
                    "){appid own authtype}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        this.usermoduleauth = jsonData.data.usermoduleauthlist;
                    }
                });
            },
            setloadMenuTree(v_nodes, v_selectedKey, v_expandedKey, v_queryPlanObj, v_filters) {
                this.nodes = v_nodes;
                this.selectedKey = v_selectedKey;
                this.expandedKey = v_expandedKey;
                this.queryPlanObj = v_queryPlanObj;
                this.filters = v_filters;
            },
            onNodeSelect(node) {
                console.log(node);
                CommonJs.onNodeSelect(node, this.selectedKey, this.currentMenuId, '出库列表', this.loadData, this
                    .setlistTitleFun, this.setsaveQueryPlan, this.setinitFilters, this.clearFilter1);
            },
            saveCustomFilter() {
                if (parseInt(this.queryPlanObj.id) >= 0) {
                    this.saveQueryPlan();
                } else {
                    this.displayQueryPlan = true;
                }
            },
            closeQueryPlan() {
                this.closeQueryPlanFun(false);
            },
            butloadinngFun(val) {
                this.butLoading = val;
            },
            closeQueryPlanFun(val) {
                this.displayQueryPlan = val;
            },
            saveQueryPlan() {
                CommonJs.saveQueryPlan(this.queryPlanObj, this.nodes, MessageTip, this.butloadinngFun, this
                    .closeQueryPlanFun, this.setsaveQueryPlan);
            },
            setsaveQueryPlan(v_queryPlanObj, v_nodes) {
                this.queryPlanObj = v_queryPlanObj;
                if (v_nodes)
                    this.nodes = v_nodes;
            },
            customFilter() {
                setTimeout(() => {
                    this.loadData(1);
                }, 10);
            },
            customFilter2() {
                setTimeout(() => {
                    this.loadData2(1);
                }, 10);
            },
            listPage(event) {
                var page = event.page;
                this.pagesize2 = event.rows;
                CommonJs.SaveDefaultPageSize(this.pagesize2, this);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortfield && sortfield != '') {
                    if (sortorder == '-1') {
                        sortorder = "desc";
                    } else {
                        sortorder = "asc";
                    }
                } else {
                    sortfield = "";
                    sortorder = "";
                }
                this.loadData(page + 1, sortfield, sortorder);
            },
            onSort(event) {
                console.log('onSort...');
                console.log(event);
                var sortfield = event['sortField'];
                var sortorder = event['sortOrder'];
                if (sortorder == '-1') {
                    sortorder = "desc";
                } else {
                    sortorder = "asc";
                }
                this.loadData(1, sortfield, sortorder);
            },
            listPage2(event) {
                var page = event.page;
                this.pagesize = event.rows;
                this.loadData2(page + 1);
            },
            loadData(v_page, v_sortfield, v_sortorder) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize2,
                    conditions: []
                };
                if (v_sortfield && v_sortorder) {
                    listwhere['sortfield'] = v_sortfield;
                    listwhere['sortorder'] = v_sortorder;
                }
                for (var key in this.filters1) {
                    if (key == 'global' && this.filters1[key]['value']) {
                        listwhere.conditions.push({
                            name: 'orderid,name,productname',
                            value: this.filters1[key]['value'],
                            operation: this.filters1[key]['matchMode']
                        });
                    }
                    if (this.filters1[key]['constraints']) {
                        for (var i = 0; i < this.filters1[key].constraints.length; i++) {
                            if (this.filters1[key].constraints[i].value && this.filters1[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters1[key].constraints[i].value,
                                    operation: this.filters1[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{outstocklist(where:" + JSON.stringify(listwhere) +
                    "){id orderid salesdate buyercode name productname mpn itemcode ordertype salesquantity unitprice fulfillmentperunit total outprice productprofit depositamount netamount outtime}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.outstocks = jsonData.data.outstocklist;
                        this.allRecords = jsonData.page.allrecord;
                        this.first = this.pagesize2 * (jsonData.page.pageindex - 1);
                        console.log(this.allRecords);
                    }
                });
            },
            loadData2(v_page) {
                this.loading = true;
                var listwhere = {
                    pageindex: v_page,
                    pagesize: this.pagesize,
                    conditions: [{
                        name: 'finish',
                        value: '0',
                        operation: 'equals'
                    }]
                };
                for (var key in this.filters2) {
                    if (this.filters2[key]['constraints']) {
                        for (var i = 0; i < this.filters2[key].constraints.length; i++) {
                            if (this.filters2[key].constraints[i].value && this.filters2[key].constraints[i].value !=
                                '') {
                                listwhere.conditions.push({
                                    name: key,
                                    value: this.filters2[key].constraints[i].value,
                                    operation: this.filters2[key].constraints[i].matchMode
                                });
                            }
                        }
                    }
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{orderlist(where:" + JSON.stringify(listwhere) +
                    "){id orderid name buyercode mpn itemcode productname salesdate salesquantity unitprice pricediscountperunit fulfillmentperunit total finaltotal marginreturnnum ordertype isreturnname rmaid storename operator storeid marginorder category currency exchangerate outtime}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadData---');
                        console.log(jsonData);
                        this.orders = jsonData.data.orderlist;
                        this.allRecords2 = jsonData.page.allrecord;
                        this.first2 = this.pagesize * (jsonData.page.pageindex - 1);
                        console.log(this.allRecords2);
                    }
                });
            },
            createOutStock() {
                if (!CommonJs.userOwerAppId(this.usermoduleauth, 'CreateOutStock')) {
                    MessageTip.warnmsg('请联系管理员开通新建出库权限');
                    return;
                }
                this.selectedRow2 = [];
                this.importProgress = '';
                this.orders = [];
                this.startoutstock = false;
                this.importProgressValue = 0;
                this.clearFilter2();
                this.loadData2(1);
                this.outstockShow = true;
            },
            saveOutStock() {
                if (!this.selectedRow2 || this.selectedRow2.length == 0) {
                    MessageTip.warnmsg('请选择要出库的订单');
                    return;
                }
                for (var i = 0; i < this.selectedRow2.length; i++) {
                    if (this.selectedRow2[i]['outtime'] == '') {
                        MessageTip.warnmsg('请填写实际出库时间');
                        return;
                    }
                }
                this.startoutstock = true;
                this.checkOutStockSuiteList(this.selectedRow2);
            },
            closeSaveOutStock() {
                this.loadData(1);
                this.importProgress = '';
                this.startoutstock = false;
                this.importProgressValue = 0;
                this.orders = [];
                this.outstockShow = false;
            },
            savetransferOutStock() {
                for (var i = 0; i < this.transferorders.length; i++) {
                    if (this.transferorders[i]['transferorderdetail'].indexOf(',') > 0) {
                        var array = this.transferorders[i]['transferorderdetail'].split(',');
                        for (var k = 0; k < array.length; k++) {
                            var array2 = array[k].split('|');
                            var cloneObj = {};
                            for (var key in this.transferorders[i]) {
                                cloneObj[key] = this.transferorders[i][key];
                            }
                            cloneObj[key]['transferorder'] = array2[0];
                            cloneObj[key]['transferorderdetail'] = array[k];
                            cloneObj[key]['salesquantity'] = array2[1];
                            this.allwaitoutorders.push(cloneObj);
                        }
                    } else {
                        var array3 = this.transferorders[i]['transferorderdetail'].split('|');
                        this.transferorders[i]['transferorder'] = array3[0];
                        this.allwaitoutorders.push(this.transferorders[i]);
                    }
                }
                this.startoutstock = true;
                this.importProgressValue = 0;
                this.importProgress = '';
                var outorders = [];
                for (var k2 = 0; k2 < this.allwaitoutorders.length; k2++) {
                    var salesquantitytemp = Number(this.allwaitoutorders[k2]['salesquantity']);
                    if (!isNaN(salesquantitytemp) && salesquantitytemp > 0) {
                        outorders.push(this.allwaitoutorders[k2]);
                    }
                }
                this.createOutStockList(outorders);
            },
            closetransferSaveOutStock() {
                this.loadData(1);
                this.importProgress = '';
                this.startoutstock = false;
                this.importProgressValue = 0;
                this.transferorders = [];
                this.cantransferorders = [];
                this.transferoutstockShow = false;
            },
            delOutStock() {
                if (!this.selectedRow || this.selectedRow.length == 0) {
                    MessageTip.warnmsg('请选择要删除订单');
                    return;
                }
                var orderIds = [];
                for (var k1 = 0; k1 < this.selectedRow.length; k1++) {
                    orderIds.push({
                        "id": this.selectedRow[k1]["id"]
                    });
                }
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "delete{outstocklist(o:" + JSON.stringify(orderIds) +
                    "){id errmsg}}"
                ).then(res => {
                    if (res.errcode == "0") {
                        this.selectedRow = null;
                        this.loadData(1);
                        MessageTip.successmsg('删除成功');
                    } else {
                        MessageTip.warnmsg('删除失败');
                    }
                });
            },
            openOutStock(v_data) {
                this.recordsubject = '出库单' + v_data.orderid;
                this.currentRow = {
                    bindpage: 'outstock',
                    id: v_data.id,
                    workflowid: '',
                    flowguid: '',
                    boxid: '',
                    stateid: '',
                    flowid: '',
                };
                this.displayMaximizable = true;
            },
            closeMaximizable() {
                this.displayMaximizable = false;
            },
            freshList() {
                this.butLoading = false;
                this.loadData(1);
            },
            saveData() {
                this.butLoading = true;
                this.$refs.universalpage.saveInfo(true);
            },
            closeButLoading(val) {
                if (val) {
                    this.butLoading = true;
                } else {
                    this.butLoading = false;
                }
            },
            openLeftPosition() {
                console.log('recordsubject:' + this.recordsubject);
                this.displayPosition = true;
            },
            closePosition() {
                this.displayPosition = false;
            },
            closeCantransferOrders() {
                this.cantransferordersShow = false;
            },
            opentransferOutStock(v_row) {
                this.cantransferordersShow = true;
                this.currenttransferrow = v_row;
                this.selectedRow3 = null;
                this.loadCanTransferOrders(v_row['mpn']);
            },
            addtransferOutStock() {
                if (!this.selectedRow3 || this.selectedRow3.length == 0) {
                    MessageTip.warnmsg('请选择要调货的订单');
                    return;
                }
                var salesquantity = Number(this.currenttransferrow['salesquantity']);
                var transferquantity = 0;
                var transferorder = '';
                var transferorderdetail = '';
                for (var i = 0; i < this.selectedRow3.length; i++) {
                    var tempnum = Number(this.selectedRow3[i]['transferquantity']);
                    if (!isNaN(tempnum)) {
                        transferquantity = transferquantity + tempnum;
                        if (transferorder == '') {
                            transferorder = this.selectedRow3[i]['orderid'];
                            transferorderdetail = this.selectedRow3[i]['id'] + '|' + tempnum;
                        } else {
                            transferorder = transferorder + ',' + this.selectedRow3[i]['orderid'];
                            transferorderdetail = transferorderdetail + ',' + this.selectedRow3[i][
                                'id'
                            ] + '|' + tempnum;
                        }
                    }
                }
                if (transferquantity != salesquantity) {
                    MessageTip.warnmsg('选择的调货的订单调货数量和需求数量不一致');
                    return;
                }
                this.currenttransferrow['transferorder'] = transferorder;
                this.currenttransferrow['transferorderdetail'] = transferorderdetail;
                this.cantransferordersShow = false;
                this.selectedRow3 = null;
            },
            checkOutStockSuiteList(outorders) {
                this.butLoading = true;
                this.importProgress = '开始检查订单数据...';
                requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid"))
                    .post(
                        '/calf/plat/OutstockSuiteCheck?appkey=' + sessionStorage.getItem("appkey"), outorders).then(
                        res => {
                            console.log(res);
                            this.butLoading = false;
                            if (res.data.errcode == "0") {
                                var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                console.log('OutstockSuiteCheck:');
                                console.log(retObj);
                                outorders = retObj;
                                this.importProgress = '订单数据检查完毕';
                                this.checkOutStockList(outorders);
                            } else {
                                MessageTip.warnmsg(res.data);
                            }
                        });
            },
            checkOutStockList(outorders) {
                var checkData = {
                    data: []
                };
                for (var i = 0; i < outorders.length; i++) {
                    checkData.data.push({
                        buyercode: outorders[i]['buyercode'],
                        itemcode: outorders[i]['itemcode'],
                        marginorder: outorders[i]['marginorder'],
                        mpn: outorders[i]['mpn'],
                        orderid: outorders[i]['orderid'],
                        ordertype: outorders[i]['ordertype'],
                        outquantity: outorders[i]['salesquantity'],
                        outtype: outorders[i]['category'],
                        tblorderid: outorders[i]['id'],
                        storeid: outorders[i]['storeid'],
                        suiteoutmark: outorders[i]['suiteoutmark'],
                        marginreturnnum: outorders[i]['marginreturnnum'],
                    });
                }
                if (checkData.data.length > 0) {
                    this.importProgress = '开始检查订单对应库存信息...';
                    this.butLoading = true;
                    requests.create_plat_requests(sessionStorage.getItem("corpid"), sessionStorage.getItem("storeid"))
                        .post(
                            '/calf/plat/OutstockCheck?appkey=' + sessionStorage.getItem("appkey"), checkData).then(
                            res => {
                                console.log(res);
                                this.butLoading = false;
                                if (res.data.errcode == "0") {
                                    var tipStr = '';
                                    var retObj = JSON.parse(unescape(Base64.decode(res.data.data)));
                                    console.log('checkOutStockList:');
                                    console.log(retObj);
                                    for (var i2 = 0; i2 < retObj.length; i2++) {
                                        if (retObj[i2].err != '') {
                                            tipStr = tipStr + retObj[i2].err + ";";
                                        }
                                    }
                                    console.log(tipStr);
                                    if (tipStr != '') {
                                        this.importProgress = tipStr;
                                    } else {
                                        if (retObj.length > 0) {
                                            this.importProgress = '开始订单出库...';
                                            this.transferorders = [];
                                            for (var i = 0; i < retObj.length; i++) {
                                                for (var j = 0; j < outorders.length; j++) {
                                                    if (retObj[i]['tblorderid'] == outorders[j]['id'] && retObj[i][
                                                            'itemcode'
                                                        ] == outorders[j]['itemcode'] && retObj[i]['mpn'] ==
                                                        outorders[j]['mpn']) {
                                                        outorders[j]['salesquantity'] = parseInt(outorders[j][
                                                            'salesquantity'
                                                        ]) - parseInt(retObj[i]['outquantity']);
                                                        var cloneObj = {};
                                                        for (var pro in outorders[j]) {
                                                            cloneObj[pro] = outorders[j][pro];
                                                        }
                                                        cloneObj['salesquantity'] = retObj[i]['outquantity'];
                                                        cloneObj['marginreturnnum'] = retObj[i]['marginreturnnum'];
                                                        cloneObj['transferorder'] = '';
                                                        cloneObj['transferorderdetail'] = '';
                                                        this.transferorders.push(cloneObj);
                                                        break;
                                                    }
                                                }
                                            }
                                            this.allwaitoutorders = outorders;
                                            this.outstockShow = false;
                                            this.startoutstock = false;
                                            this.transferoutstockShow = true;
                                        } else {
                                            this.createOutStockList(outorders);
                                        }
                                    }
                                } else {
                                    MessageTip.warnmsg(res.data);
                                }
                            });
                }
            },
            loadCanTransferOrders(v_mpn) {
                this.loading = true;
                var listwhere = {
                    pageindex: 1,
                    pagesize: 50,
                    conditions: [{
                        name: 'querycantransfer',
                        value: '',
                        operation: 'equals'
                    }, {
                        name: 'finish',
                        value: '1',
                        operation: 'equals'
                    }, {
                        name: 'mpn',
                        value: v_mpn,
                        operation: 'equals'
                    }, {
                        name: 'category',
                        value: '3',
                        operation: 'equals'
                    }]
                };
                requests.graphql_requests.post(
                    '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                    "{orderlist(where:" + JSON.stringify(listwhere) +
                    "){id orderid name buyercode mpn itemcode productname salesdate salesquantity unitprice pricediscountperunit fulfillmentperunit total finaltotal ordertype isreturnname rmaid storename operator storeid marginorder category}}"
                ).then(res => {
                    this.loading = false;
                    if (res.errcode == "0") {
                        var jsonData = JSON.parse(unescape(Base64.decode(res.data)));
                        console.log('loadCanTransferOrders---');
                        console.log(jsonData);
                        for (var i = 0; i < jsonData.data.orderlist.length; i++) {
                            jsonData.data.orderlist[i]['transferquantity'] = 0;
                        }
                        this.cantransferorders = jsonData.data.orderlist;
                    }
                });
            },
            createOutStockList(outorders) {
                if (outorders.length > 0) {
                    outorders.sort((a, b) => a.outtime.localeCompare(b.outtime));
                    console.log('createOutStockList....');
                    console.log(outorders);
                    this.importProgress = '共' + outorders.length + '条数据,已处理0条,还剩余' + outorders.length + '条';
                    setTimeout(() => {
                        this.continueCreateOutStock(outorders, 0, 0, 0);
                    }, 10);
                }
            },
            continueCreateOutStock(alldatas, importIndex, failureIndex, currentIndex) {
                if (alldatas.length > currentIndex) {
                    this.importProgress = '共' + alldatas.length + '条数据,正处理第' + (currentIndex + 1) +
                        '条数据,已成功处理' + importIndex + '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex -
                            failureIndex) + '条';
                    var orderObj = alldatas[currentIndex];
                    if (orderObj['suiteoutmark'] && orderObj['suiteoutmark'] != '') {
                        var suiteoutmarkstr = orderObj['suiteoutmark'];
                        var outStockBlotterObjs = [];
                        outStockBlotterObjs.push({
                            id: '-1',
                            buyercode: orderObj['buyercode'],
                            fulfillmentperunit: orderObj['fulfillmentperunit'],
                            itemcode: orderObj['itemcode'],
                            marginorder: orderObj['marginorder'],
                            mpn: orderObj['mpn'],
                            name: orderObj['name'],
                            orderid: orderObj['orderid'],
                            ordertype: orderObj['ordertype'],
                            outquantity: orderObj['salesquantity'],
                            outtype: orderObj['category'],
                            productname: orderObj['productname'],
                            salesdate: orderObj['salesdate'],
                            salesquantity: orderObj['salesquantity'],
                            storeid: orderObj['storeid'],
                            tblorderid: orderObj['id'],
                            total: orderObj['total'],
                            unitprice: orderObj['unitprice'],
                            transferorder: orderObj['transferorder'],
                            suiteoutmark: orderObj['suiteoutmark'],
                            instoragecurrency: orderObj['instoragecurrency'],
                            instorageexchangerate: orderObj['instorageexchangerate'],
                            ordercurrency: orderObj['ordercurrency'],
                            orderexchangerate: orderObj['orderexchangerate'],
                            transferorderdetail: orderObj['transferorderdetail'],
                            outtime: orderObj['outtime'],
                            originalproductid: orderObj['originalproductid'],
                            marginreturnnum: orderObj['marginreturnnum'],
                        });
                        var startIndex = currentIndex + 1;
                        for (var i = startIndex; i < alldatas.length; i++) {
                            orderObj = alldatas[i];
                            if (orderObj['suiteoutmark'] && orderObj['suiteoutmark'] == suiteoutmarkstr) {
                                outStockBlotterObjs.push({
                                    id: '-1',
                                    buyercode: orderObj['buyercode'],
                                    fulfillmentperunit: orderObj['fulfillmentperunit'],
                                    itemcode: orderObj['itemcode'],
                                    marginorder: orderObj['marginorder'],
                                    mpn: orderObj['mpn'],
                                    name: orderObj['name'],
                                    orderid: orderObj['orderid'],
                                    ordertype: orderObj['ordertype'],
                                    outquantity: orderObj['salesquantity'],
                                    outtype: orderObj['category'],
                                    productname: orderObj['productname'],
                                    salesdate: orderObj['salesdate'],
                                    salesquantity: orderObj['salesquantity'],
                                    storeid: orderObj['storeid'],
                                    tblorderid: orderObj['id'],
                                    total: orderObj['total'],
                                    unitprice: orderObj['unitprice'],
                                    transferorder: orderObj['transferorder'],
                                    suiteoutmark: orderObj['suiteoutmark'],
                                    instoragecurrency: orderObj['instoragecurrency'],
                                    instorageexchangerate: orderObj['instorageexchangerate'],
                                    ordercurrency: orderObj['ordercurrency'],
                                    orderexchangerate: orderObj['orderexchangerate'],
                                    transferorderdetail: orderObj['transferorderdetail'],
                                    outtime: orderObj['outtime'],
                                    originalproductid: orderObj['originalproductid'],
                                    marginreturnnum: orderObj['marginreturnnum'],
                                });
                            } else {
                                break;
                            }
                        }
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "mutation{outstockblotterlist(o:" + JSON.stringify(outStockBlotterObjs) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                importIndex = importIndex + outStockBlotterObjs.length;
                            } else {
                                MessageTip.warnmsg('处理第' + (currentIndex + 1) + "到" + (currentIndex + 1 +
                                        outStockBlotterObjs.length) +
                                    '条数据失败');
                                failureIndex = failureIndex + outStockBlotterObjs.length;
                            }
                            console.log('continueCreateOutStock...suiteoutmark..');
                            console.log(outStockBlotterObjs.length);
                            currentIndex = currentIndex + outStockBlotterObjs.length;
                            this.importProgressValue = (((importIndex + failureIndex) / alldatas.length) * 100)
                                .toFixed(0);
                            console.log('importProgressValue:' + this.importProgressValue);
                            console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                            if (this.startoutstock) {
                                setTimeout(() => {
                                    this.continueCreateOutStock(alldatas, importIndex, failureIndex,
                                        currentIndex);
                                }, 10);
                            }
                        });
                    } else {
                        var outStockBlotterObj = {
                            id: '-1',
                            buyercode: orderObj['buyercode'],
                            fulfillmentperunit: orderObj['fulfillmentperunit'],
                            itemcode: orderObj['itemcode'],
                            marginorder: orderObj['marginorder'],
                            mpn: orderObj['mpn'],
                            name: orderObj['name'],
                            orderid: orderObj['orderid'],
                            ordertype: orderObj['ordertype'],
                            outquantity: orderObj['salesquantity'],
                            outtype: orderObj['category'],
                            productname: orderObj['productname'],
                            salesdate: orderObj['salesdate'],
                            salesquantity: orderObj['salesquantity'],
                            storeid: orderObj['storeid'],
                            tblorderid: orderObj['id'],
                            total: orderObj['total'],
                            unitprice: orderObj['unitprice'],
                            transferorder: orderObj['transferorder'],
                            suiteoutmark: orderObj['suiteoutmark'],
                            instoragecurrency: orderObj['instoragecurrency'],
                            instorageexchangerate: orderObj['instorageexchangerate'],
                            ordercurrency: orderObj['ordercurrency'],
                            orderexchangerate: orderObj['orderexchangerate'],
                            transferorderdetail: orderObj['transferorderdetail'],
                            outtime: orderObj['outtime'],
                            originalproductid: orderObj['originalproductid'],
                            marginreturnnum: orderObj['marginreturnnum'],
                        };
                        requests.graphql_requests.post(
                            '/graphql/?appkey=' + sessionStorage.getItem("appkey"),
                            "mutation{outstockblotter(o:" + JSON.stringify(outStockBlotterObj) +
                            "){id errmsg}}"
                        ).then(res => {
                            if (res.errcode == "0") {
                                importIndex = importIndex + 1;
                            } else {
                                MessageTip.warnmsg('处理第' + (currentIndex + 1) + '条数据失败');
                                failureIndex = failureIndex + 1;
                            }
                            currentIndex = currentIndex + 1;
                            this.importProgressValue = (((importIndex + failureIndex) / alldatas.length) * 100)
                                .toFixed(0);
                            console.log('importProgressValue:' + this.importProgressValue);
                            console.log('(' + importIndex + '+' + failureIndex + ')/' + alldatas.length);
                            if (this.startoutstock) {
                                setTimeout(() => {
                                    this.continueCreateOutStock(alldatas, importIndex, failureIndex,
                                        currentIndex);
                                }, 10);
                            }
                        });
                    }
                } else {
                    this.importProgress = '共' + alldatas.length + '条数据,已成功处理' + importIndex +
                        '条,失败' + failureIndex + '条,还剩余' + (alldatas.length - importIndex - failureIndex) + '条';
                }
            },
            dialogmsgClose() {
                MessageTip.onClose();
            },
            selfmaximize() {
                CommonJs.selfmaximize();
            },
            dialogClose() {
                this.butLoading = false;
            }
        },
        components: {
            NewUniversalPage,
            'AppFooter': AppFooter,
        }
    }
</script>